import { useLayoutEffect } from "react";
import { setCookie } from "@utils/cookie";
import { useLocation } from "react-router-dom";

const COOKIE_EXPIRE_DAYS = 1;
/**
 * Checks URL for 'aid' parameter, sets it as a cookie with 1-day expiration,
 */
const useAidCookie = () => {
  const location = useLocation();

  useLayoutEffect(() => {
    const params = new URLSearchParams(location.search);
    const queryAid = params.get("aid");

    if (queryAid) setCookie("aid", queryAid, COOKIE_EXPIRE_DAYS);
  }, [location.search]);
};

export default useAidCookie;
