import React, { useEffect, useCallback, useContext } from "react";
import { useIntercom } from "react-use-intercom";
import { Header } from "@really5g/ui-components";
import { Outlet, ScrollRestoration, useLocation } from "react-router-dom";
import SubscriptionDataContext from "@context/SubscriptionDataContext";
import { stepPaths } from "@utils/navigation";

export const Layout: React.FC = () => {
  const { boot } = useIntercom();
  const { pathname } = useLocation();
  const { navigation } = useContext(SubscriptionDataContext);

  const shouldShowBackButton = useCallback(() => {
    return stepPaths.includes(pathname);
  }, [pathname]);

  const onBackCallback = useCallback(() => {
    navigation.goBack();
  }, [navigation]);

  useEffect(() => {
    boot();
  }, []);

  return (
    <div className="white min-h-screen font-really">
      <Header
        logoLink={null}
        showBackButton={shouldShowBackButton()}
        backCallback={onBackCallback}
      />
      <ScrollRestoration />
      <main>
        <Outlet />
      </main>
    </div>
  );
};
