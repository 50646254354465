import { createContext } from "react";

// We need to clear this interface, we are not using a ton of properties
export interface SubscriptionFlowData {
  phoneBrand: { label: string; value: string };
  phoneModel: { label: string; value: string };
  is_esim: boolean;
  isPhoneUnlocked: boolean;
  isPortedNumber?: boolean;
  portedNumber?: string;
  carrier: string;
  first_name: string;
  last_name: string;
  user_email: string;
  user_password: string;
  user_phone: string;
  user_address: string;
  user_address2: string;
  user_city: string;
  user_state: string;
  user_zipcode: string;
  user_promocode: string;
  user_radio_space_available: boolean;
  user_high_speed_internet: boolean;
  imei: string;
  lines: any;
  numberOfLines: number;
  terms: boolean;
  selectedPlan?: Plan;
  zipcode: string;
  showHint: boolean;
  paymentData?: PaymentData;
  checkout: {
    order_number: string;
  };
}

interface PaymentData {
  discount: number;
  message: string;
  sub_total: number;
  tax: number;
  total: number;
}

interface SubscriptionDataContextType {
  plans: Plan[];
  setPlans: (plans: Plan[]) => void;
  data: SubscriptionFlowData;
  addData: (data: any) => void;
  setGoBack: (fn: () => void) => void;
  navigation: {
    goBack: () => void;
  };
}

const SubscriptionDataContext = createContext<SubscriptionDataContextType>({
  data: {
    phoneBrand: { label: "", value: "" },
    phoneModel: { label: "", value: "" },
    is_esim: true,
    isPhoneUnlocked: false,
    isPortedNumber: undefined,
    portedNumber: undefined,
    carrier: "",
    first_name: "",
    last_name: "",
    user_email: "",
    user_password: "",
    user_phone: "",
    user_address: "",
    user_address2: "",
    user_city: "",
    user_state: "",
    user_zipcode: "",
    user_promocode: "",
    user_radio_space_available: false,
    user_high_speed_internet: false,
    imei: "",
    numberOfLines: 0,
    lines: [],
    terms: false,
    selectedPlan: undefined,
    zipcode: "",
    showHint: true,
    checkout: {
      order_number: "",
    },
  },
  setPlans: () => {},
  addData: () => {},
  setGoBack: () => {},
  navigation: {
    goBack: () => null,
  },
  plans: null as unknown as Plan[],
});

export default SubscriptionDataContext;
