export const isGA4Available = window?.dataLayer;

export const sendGAPageEvent: (pathname: string) => void = (pathname) => {
  sendGAEvent("virtualPageview", {
    pageUrl: pathname,
    pageTitle: pathname,
  });
};

export const sendGAEvent: GAEvent = (event, data) => {
  window?.dataLayer?.push({
    event,
    ...data,
  });
};
