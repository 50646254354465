export const shouldNavigateToHome: (pathname: string, data: any) => boolean = (
  pathname,
  data,
) => {
  if (pathname === "/add-your-phone") {
    return !data.planId;
  }

  if (pathname === "/account") {
    return !data.planId || !data.phoneBrand || !data.phoneModel;
  }

  return false;
};

export const defaultSteps = [
  { label: "basic info", path: "/basic-info" },
  { label: "purchase plan", path: "/purchase-plan" },
  { label: "install & activate SIM", path: "/install-esim" },
];

export const stepPaths = ["/basic-info", "/purchase-plan"];
