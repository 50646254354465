// @ts-expect-error ...
import React from "react";
import tw from "twin.macro";
import { Spinner } from "@components/commons/Spinner";

const Container = tw.div`w-full flex justify-center`;

export const SpinnerFallback = () => (
  <Container>
    <Spinner />
  </Container>
);
